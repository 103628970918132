import axios from "axios";

export const fetchAndTransformProductListing = async () => {
    const listingResponse = await axios.get(
        `https://raw.githubusercontent.com/stephenjesus/react-demo/main/productListing.json`
    );
    return listingResponse.data.map((product, index) => ({
        id: index + 1,
        name: product.name,
        title: `${product.name} Placement Papers`,
        discountPrice: 49,
        price: 299,
        image: product.image,
        category: "MNC",
        path: `question-paper${product.path}`,
    }));
};
